import React from 'react';
import { graphql } from 'gatsby';
import NewsPage from 'components/NewsPage/NewsPage';
import NewsColumns from 'components/NewsColumns/NewsColumns';

class News extends React.Component {
  render() {
    const { data } = this.props;
    return (
      <NewsPage>
        <NewsColumns articles={data.allMarkdownRemark.edges} />
      </NewsPage>
    );
  }
}

export default News;

export const query = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___createdAt], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            date
            title
            path
          }
        }
      }
    }
  }
`;
